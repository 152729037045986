<script setup lang="ts">
import { getLanguageName } from "@shopware/helpers";
import type {CmsElementVideoBox} from "~/composables/useCustom";

const props = defineProps<{
  class?: any;
}>();

const { languages, changeLanguage, replaceToDevStorefront } =
  useInternationalization();
const { languageIdChain } = useSessionContext();

const onChangeHandler = async (id: any) => {
  const data = await changeLanguage(id);

  if (data.redirectUrl) {
    window.location.replace(replaceToDevStorefront(data.redirectUrl));
  } else {
    window.location.reload();
  }
};

const getLanguageCode = (language: any) => {
  if(!language) {
    return "de";
  }
  const languageCode = language.translationCode.code;
  const codeArray = languageCode.split('-');
  return codeArray[0]
}

const isVisible = ref(false)
const currentLanguage = ref('de');

const languageToggle = () => {
  isVisible.value = !isVisible.value;
}

const currLang = languages.value.find(lang => lang.id === languageIdChain.value);

currentLanguage.value = getLanguageCode(currLang)


</script>
<template >
  <div class="dropdown-custom" :class="props?.class">
    <a href="#" class="dropdown__btn js-dropdown"  @click="languageToggle">
      <span class="dropdown__btn-inner">
        <span>{{ currentLanguage }}</span>
      </span>

      <span class="dropdown__arrow">
        <i class="ico-arrow">
          <img src="assets/images/svg/arrow.svg" alt="" width="9" height="8">
        </i>
      </span>
    </a>

    <div :class="{'d-block': isVisible, 'dropdown__inner': true}">
      <ul aria-label="Select language">
        <li v-for="language in languages"
            :key="language.id"
            class="cursor-pointer hover:bg-gray-200"
            :class="{'bg-gray-300': languageIdChain === language.id}"
            :aria-selected="languageIdChain === language.id">
          <a @click="onChangeHandler(language.id)" href="javascript:;">{{ getLanguageCode(language) }}</a>
        </li>
      </ul>

    </div><!-- /.dropdown__inner -->
  </div>

  <select aria-label="Select language"
    class="d-none"
    @change="onChangeHandler"
  >
    <option v-for="language in languages"
      :key="language.id"
      :value="language.id"
      :selected="languageIdChain === language.id"
      :label="getLanguageName(language)"
    >
      {{ getLanguageName(language) }}
    </option>
  </select>
</template>
