<script setup lang="ts">
import {useCachedCmsElementsFromCategory} from "~/components/server/useCachedCmsElementsFromCategory";

const layoutId = "018dd106897b7e0db35027d90be7c88f"

let cmsElementsFromCategory = ref();
cmsElementsFromCategory.value = await useCachedCmsElementsFromCategory(layoutId);

</script>
<template>
  <CmsPage v-if="cmsElementsFromCategory?.cmsPage" :content="cmsElementsFromCategory.cmsPage" />
</template>