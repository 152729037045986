<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';
const { count } = useCart();
const { count: wishlistCount } = useWishlist();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const viewport = await useViewport()

watch(viewport.breakpoint, (newBreakpoint, oldBreakpoint) => {
  console.log('Breakpoint updated:', oldBreakpoint, '->', newBreakpoint)
})

const emptyCartController = useModal();
const miniCartController = useModal();

// Refs for the elements
const header__content = ref(null);
const header__inner = ref(null);
//const header__placeholder = ref(null);
const js_nav_trigger = ref(null);

const handleScroll = () => {
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  if (scrollTop >= 47) {
    header__inner.value?.classList.add('sticky-header');
    //header__placeholder.value?.classList.add('d-block');
  } else {
    header__inner.value?.classList.remove('sticky-header');
    //header__placeholder.value?.classList.remove('d-block');
  }
};

const MobileNav = (forceClose = false) => {
  if (js_nav_trigger.value?.classList.contains("nav-trigger--active") || forceClose) {
    window.document.body.classList.remove("no-scroll");
    js_nav_trigger.value?.classList.remove("nav-trigger--active");
    header__content.value?.classList.remove('is-active');
  } else {
    window.document.body.classList.add("no-scroll");
    js_nav_trigger.value?.classList.add("nav-trigger--active");
    header__content.value?.classList.add('is-active');
  }
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

</script>

<template>

  <div class="relative bg-white" aria-label="top-navigation" >
    <SharedModal :controller="emptyCartController" popup-class="empty-card">
      <span />
    </SharedModal>

    <SharedModal :controller="miniCartController" popup-class="checkout-side-cart" size="modal-xl">
      <CheckoutSideCart
          class="mini-cart"
          :controller="miniCartController"
          @close="miniCartController.close"
          @success="miniCartController.close"
      />
    </SharedModal>

    <header class="header">
      <div class="bar header__bar">
        <div class="container">
          <ul class="list-info list-unstyled justify-content-center">
            <li>
              <i class="ico-time">
                <img src="assets/images/svg/time.svg" :alt="$t('header_bar.usp2')" width="27" height="27">
              </i>
              <span>{{ $t('header_bar.usp2') }}</span>
              <div style="border-right:1px solid #fff;margin-left:10px;">&nbsp;</div>
              <a aria-label="Chat on WhatsApp" href="https://wa.me/41774404742" target="_blank">
              <svg style="margin-left:10px;" fill="#fff" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                   viewBox="0 0 308 308" xml:space="preserve">
                  <g id="XMLID_468_">
                    <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
                      c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
                      c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
                      c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
                      c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
                      c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
                      c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
                      c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
                      c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
                      C233.168,179.508,230.845,178.393,227.904,176.981z"/>
                    <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
                      c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
                      c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
                       M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
                      l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
                      c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
                      C276.546,215.678,222.799,268.994,156.734,268.994z"/>
                  </g>
                  </svg>
              </a>
            </li>
          </ul><!-- /.list-info -->
        </div><!-- /.container -->
      </div><!-- /.bar header__bar -->
      <!--div class="header-placeholder" ref="header__placeholder" /-->
      <div class="header__inner" ref="header__inner">
        <div class="container">
          <div class="header__inner-content">
            <div class="header__inner-wrapper">
              <NuxtLink class="logo header__logo" :to="formatLink(`/`)">
                <img src="assets/images/svg/logo.svg" height="91" alt="luftkuss.ch">
              </NuxtLink>

              <div style="display: none;" class="d-flex d-lg-none justify-content-end align-items-center" >
                <div class="mobile-cart-cont">
                  <div class="mobile-icon-holder">
                    <a href="javascript:;" aria-label="Mini cart"
                       data-testid="cart-button"
                       @click="miniCartController.open"
                    >
                      <i class="ico-cart-grey">
                        <img src="assets/images/svg/cart-grey.svg" alt="" width="24" height="24">
                      </i>
                      <span class="cart-count" v-if="count > 0">
                              {{ count || "" }}
                            </span>
                      <span class="sr-only">{{ $t("cart.itemsInCart") }}, {{ $t("cart.viewCart") }}</span>
                    </a>
                  </div>
                </div>
                <LanguageSwitcher class="d-none mr-2" :class="{'force-visible': viewport.isLessThan('lg')}" v-if="viewport.isLessThan('lg')" />
                <div ref="js_nav_trigger" id="js-nav-trigger" class="nav-trigger js-nav-trigger visible-xs-inline-block visible-sm-inline-block" @click="MobileNav()">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div><!-- /.header__inner-wrapper -->
            <div id="mobile-header-collapse" class="header__content" ref="header__content">

              <LayoutStoreSearch :class="{'mobile-search container': viewport.isLessThan('xl')}" @link-clicked="MobileNav(true)" /><!-- /.search -->

              <div class="header__content-inner" ref="header_inner">
                <LanguageSwitcher v-if="viewport.isGreaterOrEquals('lg')"  />

                <div class="dropdown-custom dropdown-custom--alt d-none d-xl-inline-block">
                  <div class="dropdown__btn js-dropdown">
								<span class="dropdown__btn-image">
									<img src="assets/images/temp/switzerland.png" alt="" >
								</span>
                  <span class="dropdown__btn-inner" style="margin-left:5px;">
									<small>{{ $t('headerShippingLabel') }}</small>
								</span>
                  </div>

                  <div class="dropdown__inner">
                    <ul>
                      <li class="is-active">
                        <a href="#">
											<span class="dropdown__inner-image">
												<img src="assets/images/temp/switzerland.png" alt="">
											</span>

                          <span>Schweiz</span>
                        </a>
                      </li>
                    </ul>
                  </div><!-- /.dropdown__inner -->
                </div><!-- /.dropdown-custom -->

                <nav class="nav-access header__nav-access">
                  <ul>
                    <li>
                      <AccountMenu />
                    </li>

                    <li class="position-relative">
                      <NuxtLink aria-label="wishlist"
                          data-testid="wishlist-button"
                          :to="formatLink(`/wishlist`)">
                        <i class="ico-favorite">
                          <img src="assets/images/svg/favorite.svg" alt="" width="24" height="24">
                        </i>
                        <span v-if="wishlistCount > 0" class="wishlist-count">{{ wishlistCount }}</span>
                        <span>{{ $t("wishlist.header") }}</span>
                      </NuxtLink>
                    </li>

                    <li class="position-relative" v-if="viewport.isGreaterOrEquals('lg')">
                      <a href="javascript:;" aria-label="Mini cart"
                         data-testid="cart-button"
                         @click="miniCartController.open">
                        <i class="ico-cart-grey">
                          <img src="assets/images/svg/cart-grey.svg" alt="" width="24" height="24">
                        </i>
                        <span class="cart-count" v-if="count > 0">
                          {{ count || "" }}
                        </span>
                        <span class="sr-only">{{ $t("cart.itemsInCart") }}, {{ $t("cart.viewCart") }}</span>
                        <span>{{ $t("cart.header") }}</span>
                      </a>
                    </li>
                  </ul>
                </nav><!-- /.nav-access -->

                <div class="header__nav-mobile mt-5 mb-5" v-if="viewport.isLessThan('xl')">
                  <nav class="navbar navbar-expand-xl">
                    <div class="container">
                      <LayoutTopNavigation type="mobile" />
                    </div>
                  </nav>
                </div>
              </div><!-- /.header__content-inner -->
            </div><!-- /.header__content -->
          </div><!-- /.header__inner-content -->
        </div><!-- /.container -->
      </div><!-- /.header__inner -->

      <div class="header__nav" v-if="viewport.isGreaterOrEquals('lg')">
        <nav class="navbar navbar-expand-lg">
          <div class="container">
            <LayoutTopNavigation type="desktop"  />
          </div>
        </nav>
      </div>
    </header>
  </div>
</template>
