<script setup lang="ts">
import {
  getTranslatedProperty,
  getCategoryRoute,
} from "@shopware/helpers";
import NavigationFooter from "~/components/layout/footer/NavigationFooter.vue";

const { navigationElements } = useNavigation({ type: "footer-navigation" });
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { t } = useI18n();

const footerBlockText = ref('')
const footerBlockLink = ref('')

function appendScriptToElement(scriptSrc: string, elementId: string) :void {
  const scriptElement = document.createElement('script');
  scriptElement.setAttribute('src', scriptSrc);
  scriptElement.setAttribute("defer", "defer");
  document.getElementById(elementId).appendChild(scriptElement);
}

function hotJarScript(){
  (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:4944477,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}

onMounted( ()=> {
  footerBlockText.value = t('footer.text_block_1.text')
  footerBlockLink.value = t('footer.text_block_1.btn_link')
  appendScriptToElement('//widgets.trustedshops.com/js/XA38594822CB71D110CA6B1F2FBA5D74C.js', 'trustedShopsBadge');
  hotJarScript();
})
</script>

<template>
  <LayoutCustomFooter />
  <footer class="footer">
    <div class="footer__inner">
      <div class="container">
        <a href="/" class="footer__logo">
          <img src="assets/images/svg/footer-logo.svg" loading="lazy" alt="luftkuss.ch" width="214" height="77">
        </a>

        <div class="footer__cols">
          <div class="footer__col footer__col--size-1">
            <h3>{{ $t('footer.text_block_1.headline') }}</h3>

            <div class="footer__entry" style="min-height: 200px;">
              <div v-html="footerBlockText" />
              <a :href="footerBlockLink" class="btn btn-default btn-outline-light footer__btn">{{$t('footer.text_block_1.btn_text')}}</a>
            </div><!-- /.footer__entry -->
          </div><!-- /.footer__col footer__col-/-size-1 -->

          <div class="footer__col footer__col--size-2">
            <NavigationFooter navtype="footer-navigation"></NavigationFooter>
          </div><!-- /.footer__col footer__col-/-size-2 -->

          <div class="footer__col footer__col--size-3">

            <NavigationFooter navtype="service-navigation"></NavigationFooter>

            <h3>{{$t('footer.luftkuss.social')}}</h3>

            <div class="socials">
              <ul>
                <li>
                  <a href="https://www.instagram.com/luftkuss.ch/" target="_blank">
                    <i class="ico-instagram">
                      <NuxtImg src="assets/images/svg/instagram.svg" loading="lazy" alt="instagram" width="32" height="32" />
                    </i>
                  </a>
                </li>

                <li>
                  <a href="https://www.facebook.com/luftkuss.ch" target="_blank">
                    <i class="ico-facebook">
                      <NuxtImg src="assets/images/svg/facebook.svg" loading="lazy" alt="facebook" width="16" height="32" />
                    </i>
                  </a>
                </li>
                <li>
                  <a href="https://ch.pinterest.com/Luftkuss_Ballonversand/"  target="_blank">
                    <i class="ico-pinterest">
                      <NuxtImg src="assets/images/svg/pinterest.svg" loading="lazy" alt="pinterest" width="32" height="32" />
                    </i>
                  </a>
                </li>
              </ul>
            </div><!-- /.socials -->
          </div><!-- /.footer__col footer__col-/-size-3 -->
        </div><!-- /.footer__cols -->
      </div><!-- /.container -->
    </div><!-- /.footer__inner -->

    <div class="footer__bar">
      <div class="container">
        <p>&copy; Time 2 Smile GmbH</p>
      </div><!-- /.container -->
    </div><!-- /.footer__bar-->
    <div id="trustedShopsBadge"></div>
  </footer><!-- /.footer -->
</template>
