<script setup lang="ts">
import type { Schemas } from "#shopware";
import { getSmallestThumbnailUrl } from "@shopware/helpers";
import { ApiClientError } from "@shopware/api-client";
import eventBus from '../utilities/EventBus';

const props = withDefaults(
  defineProps<{
    cartItem: Schemas["LineItem"];
    maxQty?: number;
  }>(),
  {
    maxQty: 100,
  },
);

const { cartItem } = toRefs(props);

const isLoading = ref(false);
const { getErrorsCodes } = useCartNotification();
const { refreshCart } = useCart();
const { pushError } = useNotifications();
const { t } = useI18n();

const {
  itemOptions,
  removeItem,
  itemTotalPrice,
  itemQuantity,
  isPromotion,
  changeItemQuantity,
} = useCartItem(cartItem);

const quantity = ref();
syncRefs(itemQuantity, quantity);

const { resolveApiErrors } = useApiErrorsResolver("account_login");

const updateQuantity = async (quantityInput: number | undefined) => {
  if (quantityInput === itemQuantity.value) return;

  isLoading.value = true;

  try {
    const response = await changeItemQuantity(Number(quantityInput));
    // Refresh cart after qty update
    await refreshCart(response);
  } catch (error) {
    if (error instanceof ApiClientError) {
      const errors = resolveApiErrors(error.details.errors);
      errors.forEach((error) => pushError(error));
    }
  }

  // Make sure that qty is the same as it is in the response
  quantity.value = itemQuantity.value;

  getErrorsCodes()?.forEach((element) => {
    pushError(t(`errors.${element.messageKey}`, { ...element }));
  });

  isLoading.value = false;
};
const debounceUpdate = useDebounceFn(updateQuantity, 800);

watch(quantity, () => debounceUpdate(quantity.value));

const removeCartItem = async () => {
  isLoading.value = true;
  await removeItem();
  isLoading.value = false;
  eventBus.$emit('removeProduct')
};

const decrementQuantity = ()=> {
  const minPurchase = (cartItem.quantityInformation && cartItem.quantityInformation.minPurchase) || 1;
  if (quantity.value > minPurchase) {
    quantity.value -= 1;
  }
}

const incrementQuantity = ()=> {
  const maxPurchase = (cartItem.quantityInformation && cartItem.quantityInformation.maxPurchase) || props.maxQty;
  if (quantity.value < maxPurchase) {
    quantity.value += 1;
  }
}
const imgSrc = getSmallestThumbnailUrl(cartItem.cover) || "/assets/images/placeholder.gif";
</script>

<template>
  <div class="card mb-3 border-bottom ">
    <div class="card-body">
      <div class="d-block d-md-flex justify-content-between">
        <div class="d-flex flex-row align-items-center" style="padding-right: 20px;">
          <div class="cart-product-image">
            <NuxtLink :to="cartItem">
              <NuxtImg
                  :src="cartItem.cover?.url ? cartItem.cover?.url : '/assets/images/placeholder.gif'"
                  :alt="`${cartItem.label || cartItem.payload.name || ''} cart item`"
                  :class="{'no-hover': (cartItem.cover?.url && cartItem.cover?.url.includes('.jpg'))}"
                  data-testid="cart-product-image"
                  width="120" height="120"
              />
            </NuxtLink>
          </div>
          <div class="ms-lg-3 mb-2">
            <h5>{{ cartItem.label }}</h5>

          </div>
        </div>
        <div class="d-flex flex-row align-items-center justify-content-between d-md-justify-content-end mt-2 d-md-mt-0">
          <div style="width: 100px;">
            <div class="counter counter--alt" v-if="(cartItem as any).quantityInformation?.maxPurchase > 1">
              <div class="counter__inner">
                <a href="#" class="btn btn-default btn-danger counter__decrement" @click="decrementQuantity()">
                  <i class="ico-minus">
                    <img src="assets/images/svg/minus.svg" alt="" width="14" height="2">
                  </i>
                </a>

                <input
                    v-model="quantity"
                    type="text"
                    :disabled="isLoading"
                    :min="(cartItem as any).quantityInformation?.minPurchase || 1"
                    :max="(cartItem as any).quantityInformation?.maxPurchase || maxQty"
                    :step="(cartItem as any).quantityInformation?.purchaseSteps || 1"
                    data-testid="cart-product-qty-select"
                    name="quantity"
                    aria-label="Cart item quantity"
                    class="counter__input"
                />
                <a href="#" class="btn btn-default btn-danger counter__increment" @click="incrementQuantity()">
                  <i class="ico-plus">
                    <img src="assets/images/svg/plus.svg" alt="" width="14" height="14">
                  </i>
                </a>
              </div><!-- /.counter__inner -->
            </div>
            </div>
          <div style="width: 120px;">
            <h5 class="mb-0">
              <span>
                  <SharedPrice
                      v-if="itemTotalPrice"
                      :value="itemTotalPrice"
                      data-testid="cart-product-price" />
                </span>
            </h5>
          </div>
          <a
              v-if="!isPromotion"
              type="button"
              class="btn btn-default btn-outline-danger btn-outline-danger--alt btn-delete-card "
              :class="{ 'text-secondary-500': isLoading }"
              data-testid="product-remove-button"
              @click="removeCartItem"
          >
            <!--{{ $t("checkout.items.removeButton") }}-->
            <svg width="14" height="14" viewBox="0 0 14 14" fill="#000" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.2987 0.709727C12.9087 0.319727 12.2787 0.319727 11.8887 0.709727L6.99875 5.58973L2.10875 0.699727C1.71875 0.309727 1.08875 0.309727 0.69875 0.699727C0.30875 1.08973 0.30875 1.71973 0.69875 2.10973L5.58875 6.99973L0.69875 11.8897C0.30875 12.2797 0.30875 12.9097 0.69875 13.2997C1.08875 13.6897 1.71875 13.6897 2.10875 13.2997L6.99875 8.40973L11.8887 13.2997C12.2787 13.6897 12.9087 13.6897 13.2987 13.2997C13.6887 12.9097 13.6887 12.2797 13.2987 11.8897L8.40875 6.99973L13.2987 2.10973C13.6787 1.72973 13.6787 1.08973 13.2987 0.709727Z" fill="url(#paint0_linear_2770_2719)"/>
              <defs>
                <linearGradient id="paint0_linear_2770_2719" x1="11.7815" y1="1.91801" x2="-0.941909" y2="8.87597" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#B14A70"/>
                  <stop offset="0.223958" stop-color="#B14A70"/>
                  <stop offset="0.708333" stop-color="#B14A70"/>
                  <stop offset="1" stop-color="#B14A70"/>
                </linearGradient>
              </defs>
            </svg>

          </a>
        </div>
      </div>
    </div>
  </div>
</template>
