<script setup lang="ts">
import { RouterLink } from "vue-router";
import type { BoxLayout, DisplayMode } from "@shopware/composables";
import { useCmsTranslations } from "@shopware/composables";
import {
  buildUrlPrefix,
  getProductName,
  getProductRoute,
  getProductFromPrice,
  getSmallestThumbnailUrl,
} from "@shopware/helpers";
import { toRefs, type Ref, computed, ref } from "vue";
import { defu } from "defu";
import SwListingProductPrice from "./SwListingProductPrice.vue";
import {
  useAddToCart,
  useNotifications,
  useProductWishlist,
  useUrlResolver,
} from "#imports";
import { useElementSize } from "@vueuse/core";
import type { Schemas } from "#shopware";
import { ApiClientError } from "@shopware/api-client";
import {addToCartTracking} from "~/components/utilities/GtmTrackings";
import Cart from "~/pages/checkout/cart.vue";

const { t } = useI18n();
const { pushSuccess, pushError, pushWarning } = useNotifications();

const props = withDefaults(
  defineProps<{
    product: Schemas["Product"];
    layoutType?: BoxLayout;
    isProductListing?: boolean;
    displayMode?: DisplayMode;
    class: any
  }>(),
  {
    layoutType: "standard",
    displayMode: "standard",
    isProductListing: false,
  },
);

type Translations = {
  product: {
    addedToWishlist: string;
    reason: string;
    cannotAddToWishlist: string;
    addedToCart: string;
    addToCart: string;
    details: string;
  };
};

let translations: Translations = {
  product: {
    addedToWishlist: "has been added to wishlist.",
    reason: "Reason",
    cannotAddToWishlist: "cannot be added to wishlist.",
    addedToCart: "has been added to cart.",
    addToCart: "Add to cart",
    details: "Details",
  },
};

translations = defu(translations, useCmsTranslations()) as Translations;

const { product } = toRefs(props);

const { addToCart, isInCart, count, quantity } = useAddToCart(product);

const { addToWishlist, removeFromWishlist, isInWishlist } = useProductWishlist(
  product.value.id,
);

const miniCartController = useModal();

const toggleWishlistProduct = async () => {
  if (!isInWishlist.value) {
    try {
      await addToWishlist();
      return pushSuccess(
        `<b>${props.product?.translated?.name}</b> ` + t("product.messages.addedToWishlist"),
          { timeout: 5000}
      );
    } catch (error) {
      if (error instanceof ApiClientError) {
        const reason = error.details.errors?.[0]?.detail
          ? `${translations.product.reason}: ${error.details.errors?.[0]?.detail}`
          : "";
        return pushError(
          `<b>${props.product?.translated?.name}</b>`  + t("product.messages.annotAddToWishlist") + `\n${reason}`,
          {
            timeout: 5000,
          },
        );
      }
    }
  }
  pushWarning(
      `<b>${props.product?.translated?.name}</b> ` + t("product.messages.removedFromWishlist"),
      { timeout: 5000}
  );
  removeFromWishlist();
};

const addToCartProxy = async () => {
  addToCartTracking(product.value, quantity.value);
  await addToCart();
  const CheckCartSideElement = document.getElementsByClassName("checkout-side-cart show");
  if(CheckCartSideElement.length === 0) {
    miniCartController.open();
  }
};

const fromPrice = getProductFromPrice(props.product);
const { getUrlPrefix } = useUrlResolver();
const ratingAverage: Ref<number> = computed(() =>
  props.product.ratingAverage ? Math.round(props.product.ratingAverage) : 0,
);

const imageElement = ref(null);
const { height } = useElementSize(imageElement);

const DEFAULT_THUMBNAIL_SIZE = 90;
function roundUp(num: number) {
  return num ? Math.ceil(num / 100) * 100 : DEFAULT_THUMBNAIL_SIZE;
}

const srcPath = computed(() => {
  let imageUrl = getSmallestThumbnailUrl(
      product.value?.cover?.media,
  );
  if(imageUrl) {
    return `${getSmallestThumbnailUrl(
        product.value?.cover?.media,
    )}`;
  }

  imageUrl = product.value?.cover?.media?.url
  if(imageUrl) {
    return imageUrl;
  }
  return null;
});

const discount = Math.floor(<number>props.product.calculatedPrice?.listPrice?.percentage) || null
</script>

<template>
  <SharedModal :controller="miniCartController" popup-class="checkout-side-cart" size="modal-xl">
    <CheckoutSideCart
        class="mini-cart"
        :controller="miniCartController"
        @close="miniCartController.close"
        @success="miniCartController.close"
        :successMessage="`<b>${props.product?.translated?.name}</b>` + $t('product.messages.addedToCart')"
    />
  </SharedModal>

  <div :class="isProductListing ? 'col-lg-4 col-sm-6 p-4' : 'w-100'" data-testid="product-box">

    <div class="card card--main cards__card">
      <div class="card__inner">
        <div class="card__image">
          <NuxtImg format="webp" loading="lazy" v-if="srcPath && srcPath.substring(srcPath.length - 3) !== 'jpg'" class="product-img" src="/assets/images/product-image-bg.jpg"  alt="bg"/>

          <RouterLink
              :to="buildUrlPrefix(getProductRoute(product), getUrlPrefix())"
              class="overflow-hidden"
          >
            <NuxtImg
                unoptimized
                provider="ipx"
                format="webp"
                fit="cover"
                :src="srcPath ? srcPath : ''"
                preset="listing"
                loading="lazy"
                data-testid="product-box-img"
                :class="{
                  'w-full h-full': true,
                  'object-cover':
                    displayMode === 'cover' ||
                    (displayMode === 'standard' && layoutType === 'image'),
                  'object-contain': displayMode === 'contain',
                  'object-scale-down':
                    displayMode === 'standard' && layoutType !== 'image',
                  'no-hover': (srcPath && srcPath.substring(srcPath.length - 3) === 'jpg')
                }"
                :alt="getProductName({ product }) || ''"

                ref="imageElement"
            >
            </NuxtImg>
          </RouterLink>

          <div class="card__actions">
            <ul class="list-badges">
              <li v-if="discount">
                <span class="badge bg-danger--light">{{ discount }}%</span>
              </li>

              <li v-if="product?.markAsTopseller">
                <span class="badge bg-danger">Top Sales </span>
              </li>
            </ul><!-- /.list-badges -->

            <a aria-label="Add to wishlist"
                href="javascript:;"
                @click="toggleWishlistProduct"
                class="btn-danger btn-round"
               :class="{'wishlist-added': isInWishlist}"
                data-testid="product-box-toggle-wishlist-button">
              <client-only>
                <i v-if="isInWishlist" class="ico-heart added">
                  <img src="assets/images/svg/heart.svg" alt="" width="16" height="14">
                </i>
                <i v-else class="ico-heart">
                  <img src="assets/images/svg/heart.svg" alt="" width="16" height="14">
                </i>
                <template #placeholder>
                  <i class="ico-heart">
                    <img src="assets/images/svg/heart.svg" alt="" width="16" height="14">
                  </i>
                </template>
              </client-only>
            </a>
          </div><!-- /.card__actions -->
        </div><!-- /.card__image -->

        <div class="card__content">
          <RouterLink :to="buildUrlPrefix(getProductRoute(product), getUrlPrefix())"
              data-testid="product-box-product-name-link">
            <h5>{{ getProductName({ product }) }}</h5>
          </RouterLink>

          <SwListingProductPrice
              :product="product"
              class="ml-auto"
              data-testid="product-box-product-price"
          />


          <div v-if="product.availableStock && product.availableStock > 0 && !product.customFields?.custom_bundle_is_bundle">
            <button
                v-if="!fromPrice"
                type="button"
                @click="addToCartProxy"
                class="btn btn-default btn-danger btn-icon w-100"
                :class="{
              'product-not-in-cart': !isInCart,
              'product-in-cart': isInCart,
            }"
                data-testid="add-to-cart-button"
            >
              <i class="ico-cart">
                <img src="assets/images/svg/cart.svg" alt="" width="20" height="20">
              </i>
              {{ $t('product.addToCart') }}
              <div v-if="isInCart" class="flex ml-2 d-none">
                <div class="w-5 h-5 i-carbon-shopping-bag text-gray-600" />
                {{ count }}
              </div>
            </button>
            <RouterLink v-else :to="buildUrlPrefix(getProductRoute(product), getUrlPrefix())" class="btn btn-default btn-outline-danger btn-outline-danger--alt">
              <span data-testid="product-box-product-show-details">{{$t('product.details.link')}}</span>
            </RouterLink>
          </div>
          <RouterLink v-else-if="product.customFields?.custom_bundle_is_bundle" :to="buildUrlPrefix(getProductRoute(product), getUrlPrefix())" class="btn btn-default btn-outline-danger btn-outline-danger--alt">
            <span data-testid="product-box-product-show-details">{{$t('product.details.link')}}</span>
          </RouterLink>
          <button v-else disabled="disabled" class="btn btn-default btn-outline-danger btn-outline-danger--alt w-100" href="javascript:;">{{ $t("checkout.items.sold.out") }}</button>

        </div><!-- /.card__content -->
      </div><!-- /.card__inner -->
    </div><!-- /.card card-/-main -->
  </div>
</template>
