import axios from "axios";

import getUrlPrefix from "~/components/cms/helpers/getUrlPrefix";

export async function useCachedCategory(categoryId: string, query: any, langId: string|null = null) {
    let languageId = '';
    const prefix = getUrlPrefix();
    if(!langId) {
        const {
            getLanguageIdFromCode,
        } = useInternationalization();

        languageId = getLanguageIdFromCode(
            prefix ? prefix : 'de-DE',
        );
    } else {
        languageId = langId;
    }

    const storeApiUrl = getOrigin() + "/api/front/" + languageId + "/category/" + categoryId;
    const response = await axios.post(storeApiUrl, query);

    return response.data;
}

function getOrigin(): string | undefined {
    let origin = "";
    const nuxtApp = useNuxtApp();
    const isDeV = process.env.NODE_ENV !== 'production';

    if (import.meta.server) {
        if (nuxtApp.ssrContext?.event.node.req.headers.host !== "undefined") {
            const location = isDeV ? "http://" : "https://"; // in dev mode we need to use http
            const host = nuxtApp.ssrContext?.event.node.req.headers.host;
            origin = location + host
        }
    } else {
        origin = window.location.origin;
    }

    return origin;
}