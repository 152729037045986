<script setup lang="ts">
import {
  getCategoryRoute,
  getTranslatedProperty,
  getSmallestThumbnailUrl,
} from "@shopware/helpers";
import type { Schemas } from "#shopware";
type NavigationElement = Schemas["Category"] & {
  activeClass?: boolean;
};

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

defineProps<{
  navigationElementChildren: Array<NavigationElement>;
}>();

const emits = defineEmits<{
  (
    e: "updateActiveClass",
    navigationId: string,
    parentId: string | undefined,

  ): void;
}>();

const emitUpdateActiveClass = (
  navigationId: string,
  parentId: string | undefined,
) => {
  emits("updateActiveClass", navigationId, parentId);
};
</script>

<template>
  <li class="dropdown-item" v-for="(childElement, index) in navigationElementChildren" :key="childElement.id">
    <NuxtLink
        :to="formatLink(getCategoryRoute(childElement))"
        :target="
          childElement.externalLink || childElement.linkNewTab ? '_blank' : ''
        "
        :class="{
          'link-active': childElement.activeClass,
        }"
        @click="emitUpdateActiveClass(childElement.id, childElement.parentId)"
      >
      {{ getTranslatedProperty(childElement, "name") }}
        <div v-if="childElement.media" class="flex">
          <img
            :src="getSmallestThumbnailUrl(childElement.media)"
            class="object-scale-down h-48 w-px-200 rounded-md"
            alt="Category image"
          />
        </div>
      </NuxtLink>
      <ul v-if="childElement.children && childElement.children.length > 0">
        <LayoutTopNavigationRecursive
          :navigation-element-children="childElement.children"
          @update-active-class="
            emitUpdateActiveClass(childElement.id, childElement.parentId)
          "
        />
      </ul>
  </li>
</template>

<style scoped>
nav .link-active {
  @apply text-secondary-900 bg-primary bg-opacity-10 rounded-lg;
}
</style>
